/**
 ** languageChoice styles
 **/
@import "variables";

.languageChoice {
  display: inline-block;
  padding-left: 20px;
  @media all and (min-width: 1220px) {
    padding: 0;
  }
  @media all and (max-width: 1220px) {
    padding-top: 10px;
  }

  .select2-container--default {
    opacity: .8;
    transition: .5s ease-in-out opacity;

    &:hover,
    &.select2-container--open {
      opacity: 1;
    }

    &.select2-container--open .select2-selection--single .select2-selection__arrow b {
      margin-top: -2px!important;
      transform: rotate(225deg);
    }

    & .select2-selection--single {
      background-color: transparent !important;
      border: none;
      box-sizing: border-box;
      border-radius: 5px;

      & .select2-selection__rendered {
        text-align: right;
        width: auto;
        display: inline-block;
        color: $default_font_color;
        font-size: 14px;
        font-weight: 600;
        line-height: 25px;
        padding-right: 25px;
        padding-left: 5px;
        @media screen and (min-width: 1220px) {
          margin-left: 20px;
        }
      }

      & .select2-selection__arrow {
        top: 6px !important;

        b {
          margin-left: -8px !important;
          margin-top: -5px !important;
          border: 2px solid $default_font_color;
          border-top-color: transparent;
          border-left-color: transparent;
          width: 8px;
          height: 8px;
          transform: rotate(45deg);
        }
      }
    }
  }
}

.partners-page .languageChoice {
  .select2-container--default {
    &.select2-container--open .select2-selection--single .select2-selection__arrow b {
      border-color: transparent transparent #ffffff transparent !important;
    }
    & .select2-selection--single {
      & .select2-selection__rendered {
        color: #ffffff;
      }
      & .select2-selection__arrow {
       b {
         border-color: #ffffff transparent transparent transparent !important;
       }
      }
    }
  }
}

.select2-container {
  //width: 100% !important;
  width: auto !important;
  //min-width: 150px;

  & .select2-selection--single {
    height: 40px !important;
    line-height: 55px;
  }

}

.select2-results__option {
  height: 38px;
  line-height: 38px;
  padding: 0 10px !important;
  font-size: 14px;

  &[aria-selected=true] {
    background-color: #fff !important;
  }
}

.select2-dropdown {
  border: 1px solid #fff !important;
  z-index: 2147483648 !important;
}

.select2-results__options {
  overflow: hidden;
  border: 1px solid #E7E7E7;

  li {
    color: #000 !important;
    background-color: #ffffff !important;
    @media all and (min-width: 1220px) {
      padding: 0 14px;
    }

    &:hover {
      background-color: #dadada !important;
      color: #000;
    }
  }
}

.select2-container--default .select2-results > .select2-results__options {
  box-sizing: content-box;
  min-height: 50px;
  margin-top: -2px;

  @media all and (min-width: 1220px) {
    margin: -2px 0 0 auto;
    max-width: 50px;
  }
}

.select2-container--open .select2-dropdown--below {
  border: none !important;
  background: transparent;
  max-width: 50px;
  @media all and (min-width: 1220px) {
    margin-left: 20px;
  }
}