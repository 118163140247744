@import "variables";

footer {
  background: #F5F5F5;
  padding-top: 50px;

  .content {
    font-size: 0;
    padding: 0 30px;
    @media screen and (min-width: 1200px) {
      padding: 0;
    }

    .support-nav,
    .features-nav,
    .price-nav,
    .business-nav,
    .footer-mob__soc {
      list-style: none;
      box-sizing: border-box;
      @media screen and (min-width: 1024px) {
        width: 25%;
      }

      .footer__heading {
        margin-bottom: 7px;
        font-size: 14px;
        font-weight: 700;
        @media all and(min-width: 786px) {
          font-size: 16px;
        }
        @media all and (min-width: 1200px) {
          font-size: 18px;
        }
      }

      li a {
        font-size: 14px;
        font-weight: normal;
        line-height: 21px;
        color: $default_font_color;
        text-decoration: none;
        transition: .25s ease-in-out color;
        @media all and (min-width: 1024px) {
          font-size: 16px;
          line-height: 30px;
        }
        @media screen and (min-width: 1200px) {
          &:hover {
            color: #4a90e2;
          }
        }
      }
    }

    .support-nav,
    .features-nav,
    .price-nav,
    .business-nav {
      margin: 0 0 30px;
      @media all and (min-width: 590px) {
        display: inline-block;
        vertical-align: top;
        width: 250px;
        margin: 0 50px 30px 0;
      }
      @media all and (min-width: 1024px) {
        width: 25%;
        margin: 0 0 50px 0;
        padding-right: 20px;
      }
    }

    .business-nav {
      .footer__heading_soc {
        display: none;
        @media screen and (min-width: 960px) {
          display: block;
          margin-top: 30px;
        }
      }
      .footer__soc {
        display: none;
        @media screen and (min-width: 960px) {
          display: inline-block;
        }
      }
    }

    .footer-mob__soc {
      margin: 20px 0 50px;
      @media all and (min-width: 665px) {
        margin: -50px 0 50px;
      }
      @media all and (min-width: 960px) {
        display: none;
      }
    }

    .footer__soc {
      display: inline-block;
      width: 30px;
      height: 30px;
      margin: 10px 20px 0 0;
      border-radius: 50%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100%;
      opacity: .8;
      transition: .25s ease-in-out opacity;
      @media screen and (min-width: 1200px) {
        &:hover {
          opacity: 1;
        }
      }

      &:last-child {
        margin-right: 0;
      }
      a {
        display: inline-block;
        width: 30px;
        height: 30px;
      }
    }

    .footer__soc_vk {
      background-image: url("../images/icons/vk.svg");
    }
    .footer__soc_fb {
      background-image: url("../images/icons/fb.svg");
    }
    .footer__soc_instagram {
      background-image: url("../images/icons/instagram.svg");
    }
    .footer__soc_ok {
      background-image: url("../images/icons/ok.svg");
    }
    .footer__soc_tw {
      background-image: url("../images/icons/tw.svg");
    }
    .footer__soc_yt {
      background-image: url("../images/icons/yt.svg");
    }

    .partners-list {
      width: 100%;
      text-align: center;
      @media all and (min-width: 768px) {
        text-align: left;
      }

      ul {
        width: 100%;
        margin: 0 auto 20px;
        padding-top: 30px;
        display: flex;
        align-items: center;
        list-style: none;
        flex-wrap: wrap;

        li {
          display: block;
          margin-left: 10px;
          margin-bottom: 10px;
          max-height: 23px;
          @media all and (min-width: 450px){
            max-height: 35px;
          }
          @media all and (min-width: 768px){
            max-height: 40px;
          }
          &:first-child {
            margin-left: 0;
          }

          img {
            max-width: 70px;
            display: block;
            max-height: 23px;

            &.mc-id-check {
              max-width: 140px;
            }

            @media all and (min-width: 450px){
              max-height: 35px;
            }
            @media all and (min-width: 768px){
              max-height: 40px;
            }

            img.assist-logo {
              max-width: 50px;
              margin-left: 5px;
              @media all and (min-width: 450px){
                max-height: 35px;
              }
              @media all and (min-width: 768px){
                max-height: 45px;
              }
            }
          }
        }
      }
    }

    .copy-block {
      color: #ece8e8;
      text-align: center;
      box-shadow: inset 0 1px 0 #E7E7E7, inset 0 -1px 0px #E7E7E7;
      @media all and (min-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      p {
        line-height: 100px;
        display: inline-block;
        font-size: 14px;
        color: #e7e7e7;
        margin-bottom: 0;
      }
    }

    .company-info-block {
      padding: 30px 0 70px;
      text-align: left;
      @media screen and (min-width: 1200px){
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: flex-start;

        .company-info {
          text-align: right;
        }
      }

      p {
        margin: 0 0 24px;
        line-height: 16px;
        font-size: 12px;
        color: $default_font_color;
      }
    }
  }
}

//Redesign 2024
.footer {
  padding: 80px 0 56px 0;
  overflow: hidden;

  &__wrapper {
    display: flex;
    gap: 80px;
    justify-content: space-between;

    @include tablet() {
      flex-direction: column;
      gap: 32px;
    }
  }

  &__left {
    width: 100%;
    max-width: 405px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    @include tablet() {
      width: 100%;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__logo {
    display: flex;
    width: 175px;

    img {
      width: 100%;
    }
  }

  &__info {
    @include text-small-semi-bold();
    font-weight: 400;
    color: $c-darkGrey;
    opacity: 60%;
  }

  &__mobile-app {
    display: flex;
    flex-direction: column;
    gap: 16px;
    @include mobile() {
      display: none;
    }
  }

  &__mobile-app-title {
    @include text-regular-semi-bold();
    color: $c-darkGrey;
    padding-bottom: 8px;
  }

  &__mobile-app-btn {
    @include text-regular-medium();
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 64px;
    border: 1px solid $c-darkGrey;
    color: $c-darkGrey;
    text-decoration: none;
    width: 100%;
    max-width: 152px;
  }

  &__right {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    @include mobile() {
      flex-wrap: wrap;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 16px;
    @include mobile() {
      gap: 24px;
    }
  }

  &__column-title {
    @include text-regular-semi-bold();
    color: $c-darkGrey;

    @include mobile() {
      display: none;
    }


  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__column-items {
    display: flex;
    flex-direction: column;
    gap: 8px;

    @include mobile() {
      gap: 0;
    }
  }

  &__column-item {
    display: flex;
    align-items: center;
    gap: 12px;
    @include text-small-semi-bold();
    font-weight: 400;
    text-decoration: none;
    color: $c-darkGrey;
    transition: $transition-short;

    &:hover {
      color: $c-mainBlue;
    }

    &.active {
      color: $c-mainBlue;
    }

    @include mobile() {
      padding: 13px 0;

      &:first-child {
        padding-top: 0;
      }
    }
  }

  &__column-items.socials {
    width: 100%;
    flex-direction: row;

    span {
      display: none;
    }

    @include mobile() {
      gap: 24px;
      .footer__column-item {
        padding: 0;
      }
    }
  }
}

.footer-additional {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px;
    padding: 32px 0;
    border-bottom: 1px solid rgba(39, 43, 53, 0.1);
    border-top: 1px solid rgba(39, 43, 53, 0.1);
    @include mobile() {
      flex-direction: column;
      gap: 0;
    }
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 4px;
    text-decoration: none;

    &:hover {
      .footer-additional__title {
        color: $c-mainBlue;
      }
    }
    @include mobile() {
      padding-bottom: 32px;
      border-bottom: 1px solid rgba(39, 43, 53, 0.1);
    }
  }

  &__middle, &__right {
    display: flex;
    gap: 24px;
    img {
      object-fit: contain;
    }
    @include mobile() {
      img {
        width: 100%;
      }
      padding-top: 24px;
    }
  }

  &__title {
    @include text-regular-medium();
    transition: $transition-short;
    font-weight: 400;
    max-width: 230px;
    color: $c-darkGrey;
  }

  &__img {
    display: flex;
    align-items: center;
  }
}

.footer-copyright {
  padding: 32px 0;
  @include mobile() {
    padding: 24px 0;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    @include mobile() {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &__title {
    @include text-small-semi-bold();
    font-weight: 400;
    color: $c-darkGrey;
  }

  &__right {
    display: flex;
    gap: 80px;
    @include mobile() {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    div{
      display: flex;
      flex-direction: column;
    }
  }

  &__item {
    @include text-small-semi-bold();
    font-weight: 400;
    color: $c-darkGrey;
    text-decoration: underline;
  }
}