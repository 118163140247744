@import "variables";

.payment-switcher,
.currency-switcher {
  position: relative;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.currency-btn,
.payment-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: auto;
  min-width: 120px;
  height: 36px;
  padding: 0 10px;
  line-height: 36px;
  color: #9B9B9B;
  border: 2px solid #9B9B9B;
  cursor: pointer;
  box-sizing: border-box;

  &:last-of-type {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border-left-width: 1px;
  }
  &:first-of-type {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    border-right-width: 1px;
  }
}

.currency-ru {
  border-right-width: 1px;
  border-left-width: 1px;
}

input[name="currency"],
input[name="payment"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: -99999px;

  & + label:hover {
    background-color: #bac0f7;
    color: #ffffff;
  }

  &:checked + label {
    background-color: #8178d3;
    color: #ffffff;
  }
}

.hidden {
  display: none;
}


.price_heading_comment_head {
  position: absolute;
}

.price_heading_comment span {
  display: block;
  text-align: center;
}

span.nds {
  position: absolute;
  font-size: 12px;
}

@include _768 {
  .price_heading_comment_head {
    font-size: 12px;
  }
}