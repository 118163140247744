.cookies {
  transition: all 1s ease;
  opacity: 1;
  height: auto;
}

.cookies.hidden {
  opacity: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
}

div.cookies__wrapper {
  position: fixed;
  bottom: 60px;
  width: 85%;
  display: flex;
  background-color: rgba(220,220,220,.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-sizing: border-box;
  border: 1px solid #93988D;
  box-shadow: 0px 0px 27px 0px rgba(34, 60, 80, 0.2);
  z-index: 1000000000;
  padding: 15px;
  border-radius: 15px;
  margin: 0 15px;
  transition: all 0.5s ease;

  @media screen and (max-width: 850px) {
    display: block;
    width: 55%;
  }

  @media screen and (max-width: 640px) {
    width: 90%;
    left: 50%;
    margin: 0;
    transform: translateX(-50%);
  }
}

div.cookies__wrapper_btn {
  display: flex;

  @media screen and (max-width: 850px) {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

div.cookies__wrapper_btn  button {
  height: 50px;

  @media screen and (max-width: 850px) {
    height: auto;
  }
}

div.cookies__wrapper_btn  button:first-child {
  margin-right: 7px;
}

table.cookie,  th.cookie, td.cookie {
  border: 1px solid black;
  border-collapse: collapse;
}