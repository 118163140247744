/* FONTS
   ========================================================================== */

/* open-sans-300 - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url('../../style/fonts/open-sans-v17-latin_cyrillic-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../../style/fonts/open-sans-v17-latin_cyrillic-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-300italic - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
  url('../../style/fonts/open-sans-v17-latin_cyrillic-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../../style/fonts/open-sans-v17-latin_cyrillic-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-regular - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('../../style/fonts/open-sans-v17-latin_cyrillic-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../../style/fonts/open-sans-v17-latin_cyrillic-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-italic - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url('../../style/fonts/open-sans-v17-latin_cyrillic-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../../style/fonts/open-sans-v17-latin_cyrillic-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600 - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
  url('../../style/fonts/open-sans-v17-latin_cyrillic-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../../style/fonts/open-sans-v17-latin_cyrillic-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600italic - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
  url('../../style/fonts/open-sans-v17-latin_cyrillic-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../../style/fonts/open-sans-v17-latin_cyrillic-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700 - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('../../style/fonts/open-sans-v17-latin_cyrillic-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../../style/fonts/open-sans-v17-latin_cyrillic-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700italic - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
  url('../../style/fonts/open-sans-v17-latin_cyrillic-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../../style/fonts/open-sans-v17-latin_cyrillic-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-800 - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
  url('../../style/fonts/open-sans-v17-latin_cyrillic-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../../style/fonts/open-sans-v17-latin_cyrillic-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-800italic - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
  url('../../style/fonts/open-sans-v17-latin_cyrillic-800italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../../style/fonts/open-sans-v17-latin_cyrillic-800italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


//Redesign 2024
@font-face {
  font-family: 'Manrope';
  font-weight: 700;
  src: local('Manrope Bold'), local('Manrope-Bold'),
  url('../../style/fonts/Manrope-Bold.woff2') format('woff2'),
  url('../../style/fonts/Manrope-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 800;
  src: local('Manrope ExtraBold'), local('Manrope-ExtraBold'),
  url('../../style/fonts/Manrope-ExtraBold.woff2') format('woff2'),
  url('../../style/fonts/Manrope-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 300;
  src: local('Manrope Light'), local('Manrope-Light'),
  url('../../style/fonts/Manrope-Light.woff2') format('woff2'),
  url('../../style/fonts/Manrope-Light.woff') format('woff');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 500;
  src: local('Manrope Medium'), local('Manrope-Medium'),
  url('../../style/fonts/Manrope-Medium.woff2') format('woff2'),
  url('../../style/fonts/Manrope-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 400;
  src: local('Manrope Regular'), local('Manrope-Regular'),
  url('../../style/fonts/Manrope-Regular.woff2') format('woff2'),
  url('../../style/fonts/Manrope-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 100;
  src: local('Manrope Thin'), local('Manrope-Thin'),
  url('../../style/fonts/Manrope-Thin.woff2') format('woff2'),
  url('../../style/fonts/Manrope-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: local('Roboto'), local('Roboto'),
  url('../../style/fonts/Roboto-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: local('Roboto'), local('Roboto'),
  url('../../style/fonts/Roboto-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: local('Roboto'), local('Roboto'),
  url('../../style/fonts/Roboto-Regular.woff') format('woff');
}
/***************************************************************************/
