@import "variables";
@import "language";
@import "currency-btn";

.alt-page:not(.partners-page) header {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, .1);
  border-bottom: 1px solid #e7e7e7;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  height: 90px;
  width: 100%;
  background-color: #fff;
  z-index: 1002;

  .content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 20px;
    max-width: 1220px;
    height: 90px;
    @media screen and (min-width: 673px) {
      padding: 0 30px;
      max-width: 1700px;
      margin: 0 auto;
      box-sizing: border-box;
    }

    #logo {
      display: inline-block;
      width: 161px;
      height: 49px;
      margin-left: -5px;
    }

    .header-language-switcher {
      display: inline-block;

      select {
        border: none;
        outline: none;
        background: transparent;
        cursor: pointer;
      }
    }

    .header-overlay {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
    }

    .visible {
      display: block;
    }
  }
}

.n-page header {
  background-color: transparent;
}

header nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: calc(100% - 200px);

  ul {
    min-width: 190px;
    li {
      margin: 0 6px;
      a {
        color: #272b35;
      }
      a.active,
      span.active {
        border-bottom: 3px solid #4a90e2;
        line-height: 89px;
        margin-bottom: -4px;
        @media screen and (max-width: 850px) {
          border: 0;
          line-height: 50px;
          padding: 0 10px;
        }
      }
    }

    .reg-btn,
    .enter-btn {
      width: 100%;
      height: 40px;
      margin: 0;
      border-radius: 5px;
      text-align: center;
    }

    .enter-btn {
      margin-right: 6px;
      border: 1px solid #7D8AFF;
      transition: all 0.3s ease 0s;
      @media screen and (min-width: 1024px) {
        &:hover {
          background-color: rgba(125, 138, 255, 0.2);
        }
        & a:hover {
          color: $default_font_color;
        }
      }
    }

    .reg-btn {
      background-color: $registration_red;
      border: 1px solid $registration_red;
      transition: all 0.3s ease-out;
    }

    .reg-btn a,
    .enter-btn a {
      display: block;
      line-height: 39px;
      padding: 0 20px;
      margin: 0;
    }

    .reg-btn a {
      color: #fff;
    }
  }
}

nav ul li,
nav ul li a,
nav ul li span {
  display: inline-block;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
}

@media screen and (max-width: 1220px) {
  .menu-top-menu-container {
    display: none;
    position: absolute;
    top: 90px;
    right: 20px;
    padding: 10px 0;
    background: #fff;
    border: 1px solid #ccc;
    z-index: 10;

    ul {
      padding: 0 20px;
    }
  }

  header {
    nav {
      ul {
        li {
          display: block;
          margin: 0;
          a {
            line-height: 21px;
            padding: 11px 0;
            width: 100%;
          }
          span.active {
            line-height: 32px;
            margin-bottom: 0;
          }
        }
        .enter-btn {
          margin: 10px 0;
        }
      }
    }
  }

  .burger-trigger {
    position: relative;
    width: 50px;
    height: 50px;
    z-index: 10;
    cursor: pointer;
  }

  .burger-toggle {
    position: relative;
    top: 10px;
    left: 13px;
    display: inline-block;
    width: 25px;
    height: 3px;
    background: #939393;
    transition: all 0.3s ease 0s;
  }
  .burger-toggle::before,
  .burger-toggle::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 25px;
    height: 3px;
    background: #939393;
    transition: all 0.3s ease 0s;
  }
  .burger-toggle::before {
    top: -8px;
  }
  .burger-toggle::after {
    top: 8px;
  }

  .burger-toggle.menu-opened,
  .partners__header .burger-toggle.menu-opened {
    background: transparent;
  }
  .burger-toggle.menu-opened::before {
    top: 0;
    transform: rotate(-45deg);
  }
  .burger-toggle.menu-opened::after {
    top: 0;
    transform: rotate(45deg);
  }
}

@media screen and (min-width: 1221px) {

  .menu-top-menu-container {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  .menu-top-menu-container ul .enter-btn {
    margin-left: 25px;
  }
  .partners__header nav ul li a,
  .partners__header nav ul .enter-btn a:hover {
    color: #ffffff;
  }

  header nav {
    height: 100%;

    ul {
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      li {
        margin: 0 8px;
      }
      .enter-btn,
      .reg-btn {
        width: 135px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .reg-btn:hover {
    background-color: $registration_red_hover;
    border-color: $registration_red_hover;
    a {
      color: #ffffff;
    }
  }
  nav ul li a:hover {
    color: #4A90E2;
    transition: all .3s ease-out;
  }
}

//Redesign 2024
.new-header {
  position: fixed;
  display: block;
  width: 100%;
  background: $c-white;
  z-index: 50;

  .new-header__contact {
    cursor: pointer;
  }

  .new-header-wrap {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: 72px;
  }

  .new-header-items {
    display: flex;
    gap: 16px;
    margin-right: 32px;

    @include _1280() {
      flex-direction: column;
      width: 100%;
      margin-right: 0;
      gap: 0;
      padding-bottom: 32px;
    }
  }

  .header-logo {
    width: 118px;
    height: 36px;
  }

  .header-left {
    display: flex;
    align-items: center;
  }

  .header-right {
    display: flex;
    align-items: center;

    @include _1280() {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      max-width: 1440px;
      margin: 0 auto;
      padding: 16px 64px 0 64px;
      position: absolute;
      top: 100%;
      left: 0;
      background: $c-white;
      border-top: 1px solid rgba(39, 43, 53, 0.1);
      display: none;
      justify-content: space-between;
      height: 92vh;
    }

    @include tablet() {
      padding: 16px 16px 16px 16px;
    }

    &.active {
      display: flex;
    }
  }

  .inline-btn {
    margin-right: 16px;
    @include _1280() {
      margin-right: 0;
      margin-top: 36px;
    }
  }

  .inline-btn, .main-btn {
    padding: 6px 24px;
    @include _1280() {
      padding: 11px 24px;
    }
  }

  .new-header-item {
    @include text-regular-normal();
    text-decoration: unset;
    color: $c-darkGrey;

    &:hover {
      color: $c-mainBlue;
    }

    &.active {
      color: $c-mainBlue;
    }

    @include _1280 {
      padding: 12px 0;
      width: 100%;

      &:first-child {
        padding-top: 0!important;
      }
    }
  }

  .new-header-burger, .new-header-close {
    display: none;
    cursor: pointer;
    @include _1280() {
      &.active {
        display: block;
      }
    }
  }

  .main-btn {
    @include _1280() {
      margin-top: 16px;
      margin-bottom: 24px;
    }
  }

  .header-right-wrapper {
    display: flex;
    width: 100%;

    @include tablet() {
      flex-direction: column;
    }
  }
}