
/**
 COLORS
*/
// fonts
$default_font_color: #272b35;

// buttons
$registration_red: #FC636B;
$registration_red_hover: #bb140b;

$main_orange: #F0952B;

//backgrounds
$shadow_gray: #f8f9fc;
$default_gray: #F6F8FD;
$deep_purple: #5956ba;
$middle_purple: #6a67ce;
$light_purple: #9392db;


/**
 BREAKPOINTS
*/

$breakpoint-mobile: 320px;
$breakpoint-tablet: 650px;
$breakpoint-laptop: 1024px;
$breakpoint-desktop: 1200px;


//Redesign 2024
$c-darkGrey: #272B35;
$c-darkGrey70: #272B35B2;
$c-signalGrey: #93988D;
$c-stroke: #93988D;
$c-whiteTheme: #F5F6F7;
$c-white: #FFF;
$c-mainBlue: #3775EC;
$c-darkBlue: #1A3762;
$c-lightBlue: #E1ECFF;
$c-secondaryBlue: #28569A;
$c-error: #DF0000;
$c-error: #DF0000;
$c-accent: #E12042;
$c-linear: linear-gradient(107.1deg, #37ADFE 2.21%, #3775EC 96.22%);
$c-linear-light: linear-gradient(95.38deg, #EDF0F9 0.42%, #D1DAE4 52.39%, #D8E2F4 101.33%);
$c-cardLinear: linear-gradient(153.11deg, #28569A 8.45%, #1A3762 86.44%);
$c-BGblurLiner: linear-gradient(107.1deg, #000B3A 2.21%, #0C60DE 44.99%, #1466EF 74.43%, #001A8B 96.22%);
$c-BGredLiner: linear-gradient(107.1deg, #ED5F78 2.21%, #E12042 96.22%);

/*
----------------------
Transition time
----------------------
EXAMPLE:
transition: $transition-btn
*/

$transition-short: 250ms ease-in-out;
$transition-reg: 500ms ease-in-out;
$transition-long: 1000ms ease-in-out;

/**
 * Media
 * EXAMPLE:
 * @include tablet() { }
*/
@mixin _1440 {
  @media (max-width: 1440px) {
    @content;
  }
}

@mixin _1280 {
  @media (max-width: 1280px) {
    @content;
  }
}

@mixin _992 {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin _768 {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin _650 {
  @media (max-width: 650px) {
    @content;
  }
}

@mixin _480 {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin _375 {
  @media (max-width: 375px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 680px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin font-headline-1() {
  //styleName: h1;
  font-family: 'Manrope', sans-serif;
  font-size: 56px;
  font-weight: 700;
  line-height: 67.2px;
  text-align: center;

  @include tablet() {
    font-size: clamp(24px, 4vw, 48px);
    line-height: clamp(33px, 4vw, 57px);
  }
}

@mixin font-headline-2() {
  //styleName: h2;
  font-family: 'Manrope', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 57.6px;

  @include tablet() {
    font-size: clamp(24px, 4vw, 48px);
    line-height: clamp(33px, 4vw, 57px);
  }
}

@mixin font-headline-3() {
  //styleName: h3;
  font-family: 'Manrope', sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;

  @include tablet() {
    font-size: clamp(24px, 4vw, 40px);
    line-height: clamp(33px, 4vw, 48px);
  }
}


@mixin font-headline-4() {
  //styleName: h4;
  font-family: 'Manrope', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 41.6px;
  text-align: center;


  @include tablet() {
    font-size: clamp(24px, 4vw, 32px);
    line-height: clamp(33px, 4vw, 41px);
  }
}

@mixin font-headline-5() {
  //styleName: h5;
  font-family: 'Manrope', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  text-align: left;

  @include tablet() {
    font-size: 24px;
    line-height: 28px;
  }
}

@mixin font-headline-6() {
  //styleName: h6;
  font-family: 'Manrope', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
}

@mixin text-medium-normal  () {
  //styleName: Text medium Normal;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}


@mixin text-medium-medium  () {
  //styleName: Text medium Medium;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
}

@mixin text-medium-large  () {
  //styleName: Text large  Medium;
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 33.6px;

  @include tablet() {
    font-size: 20px;
    line-height: 28px;
  }
}
@mixin text-regular-semi-bold  () {
  //styleName: Text regular Semi Bold;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

@mixin text-regular-normal  () {
  //styleName: Text regular Semi Bold;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

@mixin text-tiny-normal  () {
  //styleName: Text tiny Normal;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}


@mixin text-regular-medium  () {
  //styleName: Text regular Medium;
  font-family: 'Roboto',  sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}


@mixin text-small-semi-bold  () {
  //styleName: Text small Semi Bold;
  font-family: 'Roboto',  sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;

}