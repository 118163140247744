@import "variables";

a, abbr, acronym, address, applet, b,
big, blockquote, body, caption, center,
cite, code, dd, del, dfn, div, dl, dt, em,
fieldset, font, form, h1, h2, h3, h4, h5,
h6, html, i, iframe, img, ins, kbd, label,
legend, li, object, ol, p, pre, q, s, samp,
small, span, strike, strong, table, tbody,
td, tfoot, th, thead, tr, tt, u, ul, var, button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: 0 0;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6875;
}

body, html {
  color: #272b35;
}

.main p,
.main a {font-size: 18px;}
input,
label,
div, a {
  box-sizing: border-box;
}

.content {
  max-width: 1180px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

h1 {
  font-size: 40px;
  font-weight: bold;
  line-height: normal;
}

h2 {
  color: #2b2b2b;
  font-size: 33px;
  line-height: 40px;
  font-weight: 700;
  display: block;
  margin-bottom: 30px;
}

.content h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  margin: 20px 0;
}

h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 25px;
  line-height: normal;
}

.fz16 {
  font-size: 16px;
}

.fz18 {
  font-size: 18px;
}

.fz20 {
  font-size: 20px;
}

.text-gray {
  color: #76797E;
}

.text-black {
  color: #000000;
}

.text-bold {
  font-weight: 700;
}

.sub-title {
  font-size: 20px;
  font-weight: normal;
}

.big-heading {
  font-weight: 800;
  font-size: 34px;
  line-height: 1.35;
  color: #000000;
}

.text-white {
  color: #ffffff;
}

blockquote {
  font-size: 16px;
  font-weight: 200;
  font-style: italic;
}

b, strong {font-weight: 600;}

.light-text {font-weight: 200;}

p {margin-bottom: 20px;}

input[type="range"] {width: 100%;}

.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

.clearfix:after {
  clear: both;
  content: ' ';
  display: block;
  font-size: 0;
  line-height: 0;
  visibility: hidden;
  width: 0;
  height: 0;
}

.hide-block {
  display: none;
}

.clearfix {display: inline-block;}

* html .clearfix {height: 1%;}

.text-center {text-align: center;}

.left {float: left;}

.right {float: right;}

.bold {font-weight: bold;}

span.bold {font-weight: 600;}

.italic {font-weight: 200;font-style: italic;
}

ul li {list-style-position: inside;}

.border-top-gray {
  border-top: 1px solid #E7E7E7;
}

.background-gray {
  background: #F8F9FC;
}

.top-offset-20 {margin-top: 20px;}
.top-offset-23 {margin-top: 23px;}
.top-offset-25 {margin-top: 25px;}
.top-offset-30 {margin-top: 30px;}
.top-offset-40 {margin-top: 40px;}
.top-offset-70 {margin-top: 70px;}
.top-offset-padding-70 {padding-top: 70px;}
.top-offset-90 {margin-top: 90px;}
.top-offset-100 {margin-top: 100px;}
.top-offset-padding-100 {padding-top: 100px;}
.top-offset-110 {margin-top: 110px;}
.top-offset-120 {margin-top: 120px;}
.top-offset-padding-120 {padding-top: 120px;}
.top-offset-160 {margin-top: 160px;}
.top-offset-170 {margin-top: 170px;}
.top-offset-200 {margin-top: 200px;}
.top-offset-210 {margin-top: 210px;}
.top-offset-220 {margin-top: 220px;}
.top-offset-245 {margin-top: 245px;}
.top-offset-245 {margin-top: 245px;}

.w570 {max-width: 570px;}
.w640 {max-width: 640px;}
.w670 {max-width: 670px;}
.w675 {max-width: 675px;}
.w700 {max-width: 700px;}
.w720 {max-width: 720px;}
.w760 {max-width: 760px;}
.w900 {max-width: 900px;}

.h630 {height: 630px;}
.h770 {height: 770px;}
.h880 {height: 880px;}
.h1100 {height: 1100px;}

.content .top-offset-105 {margin-top: 105px;}

.top-offset-padding-90 {padding-top: 90px;}

.top-offset-150,
.double-block-group.top-offset-150 {
  margin-top: 150px;
}

.top-offset-175 {margin-top: 175px;}

.content .top-offset-200 {margin-top: 200px;}

.content .top-offset-400 {margin-top: 400px;}

.offset {padding: 150px 0;}

.bottom-offset-padding-70 {padding-bottom: 70px;}
.bottom-offset-padding-80 {padding-bottom: 80px;}
.bottom-offset-padding-100 {padding-bottom: 100px;}
.bottom-offset-padding-110 {padding-bottom: 110px;}
.bottom-offset-padding-120 {padding-bottom: 120px;}
.bottom-offset-0 {margin-bottom: 0;}
.bottom-offset-5 {margin-bottom: 5px;}
.bottom-offset-10 {margin-bottom: 10px;}
.bottom-offset-15 {margin-bottom: 15px;}
.bottom-offset-20 {margin-bottom: 20px;}
.bottom-offset-23 {margin-bottom: 23px;}
.bottom-offset-30 {margin-bottom: 30px;}
.bottom-offset-35 {margin-bottom: 35px;}
.bottom-offset-40 {margin-bottom: 40px;}
.bottom-offset-50 {margin-bottom: 50px;}
.bottom-offset-55 {margin-bottom: 55px;}
.bottom-offset-70 {margin-bottom: 70px;}
.bottom-offset-100 {margin-bottom: 100px;}
.bottom-offset-110 {margin-bottom: 110px;}
.bottom-offset-150 {margin-bottom: 150px;}
.bottom-offset-180 {margin-bottom: 180px;}

.margin-auto {margin: 0 auto;}

.triple-block-group.bottom-offset {padding-bottom: 187px;}

.inline-block {display: inline-block;}
.relative {position: relative;}

.content .not-left-margin,
.not-left-margin {margin-left: 0;}
.not-right-margin {margin-right: 0 !important;}
.not-top-margin {margin-top: 0 !important;}
.not-margin {margin: 0 !important;}

.center-block {
  margin-left: auto;
  margin-right: auto;
}

.default-section {overflow:hidden; position: relative;}

.content p a,
#registration-form a {
  color: #4a90e2;
  text-decoration: none;
}
.agreement-content p a {
  color: #4a90e2;
  text-decoration: none;
}
.content p a:hover {border-bottom: 1px solid #4a90e2;}
.agreement-content p a:hover {border-bottom: 1px solid #4a90e2;}

.red-color {color: #f1453d;}

.white-color {color: #fff;}

::-webkit-input-placeholder {color: #E7E7E7;}

::-moz-placeholder {color: #E7E7E7;}

 //Firefox 19+
:-moz-placeholder {color: #E7E7E7;}

 //Firefox 18-
:-ms-input-placeholder {color: #E7E7E7;}

.general-button {
  display: inline-block;
  width: 100%;
  padding: 20px 25px;
  border-radius: 10px;
  font-size: 18px;
  line-height: 25px;
  font-weight: 800;
  text-align: center;
  text-decoration: none;
  @media all and (min-width: 426px) {
    max-width: 260px;
  }
}

.registration-button {
  color: #ffffff;
  background-color: $registration_red;
}

.main-red-btn {
  color: #ffffff;
  background-color: $registration_red;

  &:hover {
    background-color: $registration_red_hover;
    border-color: $registration_red_hover;
  }

  &[disabled] {
    opacity: 0.6;
    cursor: default;
    pointer-events: none;
  }
}

.main-orange-btn {
  color: #ffffff;
  background: $main_orange;
  display: inline-block;
  padding: 20px 40px;
  border-radius: 30px;
  font-size: 22px;
  line-height: 25px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  letter-spacing: 2px;
  text-shadow: 2px 2px 2px rgba(0,0,0, 0.4);
}


.desktop-button {
  display: none;
}

.text-paragraph {
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
}

.to-template-button {
  padding: 20px 10px;
  color: #ffffff;
  background-color: $middle_purple;
}

.block-wrapper {
  display: flex;
  flex-flow: column nowrap;
}
.block-image {
  width: 260px;
  margin: 0 auto;
}
.block-content {
  @media all and (min-width: 425px) {
    text-align: center;
  }
}

@media all and (min-width: 1024px) {
  .general-button {
    max-width: 315px;
    &:hover {
      cursor: pointer;
    }
  }

  .registration-button {
    &.desktop-button {
      transition: background-color 0.3s ease-out;
      &:hover {
        background-color: $registration_red_hover;
      }
    }
  }

  .to-template-button {
    &.general-button {
      transition: background-color 0.3s ease-out;
      &:hover {
        background-color: #0946aa;
      }
    }
  }

  .text-paragraph {
    font-size: 16px;
    line-height: 24px;
  }
}

//Redesign 2024

.container-xl {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 64px;
  @include tablet() {
    padding: 0 16px;
  }
}

.container-l {
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 64px;
  @include tablet() {
    padding: 0 16px;
  }
}

.padding-block {
  padding: 112px 0;
  @include tablet() {
    padding: 56px 0;
  }
}

.main-btn {
  @include text-medium-medium();
  position: relative;
  display: inline-block;
  color: $c-white;
  padding: 12px 24px;
  margin: 0;
  border: none;
  border-radius: 64px;
  overflow: hidden; /* для скрытия псевдоэлемента за границами кнопки */
  transition: color $transition-short;
  text-decoration: none;
  z-index: 2;
  text-align: center;
  cursor: pointer;

  @include mobile() {
    width: 100%;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 64px;
    transition: opacity $transition-short;
    z-index: -1;
  }

  &:before {
    background: $c-linear;
    opacity: 1;
  }

  &:after {
    background: $c-darkBlue;
    opacity: 0;
  }

  &:hover:before {
    opacity: 0;
  }

  &:hover:after {
    opacity: 1;
  }

  /* Обеспечиваем, чтобы текст и прочее содержимое кнопки было над псевдоэлементами */
  & > * {
    position: relative;
    z-index: 1;
  }
}

.main-btn.red {
  &:before {
    background: $c-BGredLiner;
    opacity: 1;
  }
}

.inline-btn {
  @include text-medium-medium();
  color: $c-mainBlue;
  border-radius: 64px;
  border: 1px solid $c-mainBlue;
  padding: 12px 24px;
  transition: $transition-short;
  text-align: center;
  text-decoration: none;
  display: inline-block;

  @include mobile() {
    width: 100%;
  }

  &__slide {
    border-radius: 12px;
    box-shadow: 0 2px 25px 0 #002A6940;
    padding: 32px 0 0 32px;
  }

  &:hover {
    color: $c-darkBlue;
    border-color: $c-darkBlue;
  }
}

.overlay-new {
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.25);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1004;
}
